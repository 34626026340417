
import { Options, Vue } from "vue-class-component";
import Menu from "./menu.vue";
import { asyncRoutes } from "../router/config";
import _ from "lodash";
import userDrop from "./user-drop";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import Breadcrumb from "../store/modules/breadcrumb";
import { RouteLocationNormalized, RouteRecordName } from "vue-router";
import axios from '@/axios-config'

@Options({
  name: "Layout",
  components: {
    Menu,
    userDrop,
  },
})
export default class Layout extends Vue {
  public menu: Array<any> = []
  public selectedKeys: (RouteRecordName | null | undefined)[] = [];
  public openKeys: (RouteRecordName | null | undefined)[] = [];
  get routerMatched() {
    const breadcrumb = getModule(Breadcrumb, store);
    return breadcrumb.matched;
  }

  get pageName() {
    const length = this.routerMatched.length;
    return this.routerMatched[length - 1].meta?.title;
  }

  /**
   * 格式化树形结构数据 生成 vue-router 层级路由表
   *
   * @param routerMap
   * @param parent
   * @returns {*}
   */
  public generator(val: any[]) {
    return val.map((item: any) => {
      const { menuName, routeName, isShow, component, path, icon, children } = item
      const currentRouter = {
        // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
        path,
        // 路由名称，建议唯一
        name: routeName,
        // 该路由对应页面的 组件 :方案1
        // component: constantRouterComponents[item.component || item.key],
        // 该路由对应页面的 组件 :方案2 (动态加载)
        component: () => import(`${component}`),
        // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
        meta: {
          title: menuName,
          icon: icon,
          show: isShow,
        },
        children: children
      }
      // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
      if (!currentRouter.path.startsWith('http')) {
        currentRouter.path = currentRouter.path.replace('//', '/')
      }
      // // 是否有子菜单，并递归处理
      if (item.children && item.children.length > 0) {
        // Recursion
        currentRouter.children = this.generator(item.children)
      }
      return currentRouter
    })
  }
  created() {
    const route: RouteLocationNormalized = this.$route;
    const matchedLength = route.matched.length;
    this.openKeys = route.matched
      .slice(0, matchedLength - 1)
      .map((item) => item.name);
    this.selectedKeys = [this.$route.name];
    const { currentUser } = store.getters
    if(currentUser){
      //动态菜单
      const menu:any=this.generator(currentUser.data.menus|| '0')
      menu.unshift(asyncRoutes[0])
      this.menu = menu
    }
  }
  public onOpenChange(openKeys: string[]) {
    if (openKeys.length > 1) {
      this.openKeys = openKeys.splice(openKeys.length - 1, 1);
    }
  }
}
