
import { Menu, SubMenu, MenuItem } from "ant-design-vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Model, Emit } from "vue-property-decorator";
import { RouteRecordRaw } from "vue-router";
import { Route } from "../ts-type/routes";
import myIcon from "@/components/icon";

@Options({
  components: {
    Menu,
    "a-sub-menu": SubMenu,
    "a-menu-item": MenuItem,
    myIcon,
  },
})
export default class MenuGroup extends Vue {
  @Prop({ type: Array })
  menu!: Array<Route>;

  private renderMenuItem(menu: any) {
    const slots = {
      title: () => <span>{menu.meta.title}</span>,
      icon: () => <myIcon name={menu.meta.icon} />,
    };
      if (menu.children && !!menu.children.length) {
      return (
        <a-sub-menu key={menu.name} v-slots={slots}>
          {menu.children.map((menuItem: any) => this.renderMenuItem(menuItem))}
        </a-sub-menu>
      );
    }
    if(menu.meta){
      if(menu.meta.show){
          return (
          <router-link key={menu.name} to={menu.path}>
              <a-menu-item key={menu.name} v-slots={slots} >
                {menu.meta.title}
              </a-menu-item>
          </router-link>
        );
        }
    }
     
  }

  render() {
    return (
      <a-menu>
        {this.menu.map((menuItem) => this.renderMenuItem(menuItem))}
      </a-menu>
    );
  }
}
